import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"
import { show as showAttribute } from "./loader/attribute"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import Error from "pages/error"
import { List as AttributeList, Create as CreateAttribute, Edit as EditAttribute } from "pages/attribute"
import { List as CMS, Create as CreateCMS } from "pages/cms"
import { List as OutletService, Create as CreateOutletService } from "pages/outlet-service"
import { List as CustomerSupport, Create as CreateCustomerSupport } from "pages/customer-support"
import { List as MsBrand, Create as CreateMsBrand } from "pages/ms-brand"
import { List as BrandModel, Create as CreateBrandModel } from "pages/brand-model"
import { List as MsProduct, Create as CreateMsProduct } from "pages/ms-product"

import AppLayout from "layouts/app-layout"
import CreateWarranty from "pages/warranty/create"
import Finish from "pages/finish"
import OpenApp from "pages/OpenApp"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },
            {
                path: "/finish-payment",
                element: (
                    <GuestRoute>
                        <Finish title={'Finish Payment'} />
                    </GuestRoute>
                )
            },
            // Guest
            {
                path: "/open-app",
                element: (
                    <GuestRoute>
                        <OpenApp title={'Open App'} />
                    </GuestRoute>
                )
            },

            // Protected
            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/warranty/create/:register_date/:month/:device_name/:unique_id/:model/:brand/:system_version/:system_name/:device_id",
                exact: false,
                element: (
                    <CreateWarranty title={'Create Warranty'}/>
                ),
            },

            // Protected, App Layout
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        // loader: showDashboard
                    },
                    {
                        path: "/cms",
                        title: "CMS",
                        element: (
                            <CMS key="/cms" />
                        )
                    },
                    {
                        path: "/cms/create",
                        title: "Create CMS",
                        element: (
                            <CreateCMS key="/cms/create" />
                        )
                    },
                    {
                        path: "/outlet-service",
                        title: "Outlet Service",
                        element: (
                            <OutletService key="/outlet-service" />
                        )
                    },
                    {
                        path: "/outlet-service/create",
                        title: "Create Outlet Service",
                        element: (
                            <CreateOutletService key="/outlet-service/create" />
                        )
                    },
                    {
                        path: "/customer-support",
                        title: "Customer Support",
                        element: (
                            <CustomerSupport key="/customer-support" />
                        )
                    },
                    {
                        path: "/customer-support/create",
                        title: "Create Customer Support",
                        element: (
                            <CreateCustomerSupport key="/customer-support/create" />
                        )
                    },
                    {
                        path: "/ms-brand",
                        title: "Master Brand",
                        element: (
                            <MsBrand key="/ms-brand" />
                        )
                    },
                    {
                        path: "/ms-brand/create",
                        title: "Create Master Brand",
                        element: (
                            <CreateMsBrand key="/ms-brand/create" />
                        )
                    },
                    {
                        path: "/brand-model",
                        title: "Master Brand Model",
                        element: (
                            <BrandModel key="/brand-model" />
                        )
                    },
                    {
                        path: "/brand-model/create",
                        title: "Create Master Brand Model",
                        element: (
                            <CreateBrandModel key="/brand-model/create" />
                        )
                    },
                    {
                        path: "/ms-product",
                        title: "Master Product",
                        element: (
                            <MsProduct key="/ms-product" />
                        )
                    },
                    {
                        path: "/ms-product/create",
                        title: "Create Master Product",
                        element: (
                            <CreateMsProduct key="/ms-product/create" />
                        )
                    },
                    {
                        path: "/attribute",
                        title: "Attribute",
                        element: (
                            <AttributeList key="/attribute" />
                        )
                    },
                    {
                        path: "/attribute/create",
                        title: "Create Attribute",
                        element: (
                            <CreateAttribute key="/attribute/create" />
                        )
                    },
                    {
                        path: "/attribute/:id/edit",
                        element: (
                            <EditAttribute />
                        ),
                        loader: showAttribute
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes