import { useEffect } from "react"
import { useSearchParams,Redirect } from "react-router-dom"

const OpenApp=()=>{

    const [searchParams, setSearchParams] = useSearchParams()
    const event_code = searchParams.get('event_code')

    // useEffect(()=>{
    //     var win = window.open('https://play.google.com/store/apps/details?id=com.GalacticSlice.OneBitAdventure', '_blank');
    //     win.focus();
    // },[])
    window.location.href='https://play.google.com/store/apps/details?id=com.GalacticSlice.OneBitAdventure'

}

export default OpenApp;